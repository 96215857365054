<template>
  <v-card class="pa-4">
    <h3 class="primary--text mb-4">Ads information</h3>
    <div class="mb-2">
      <label class="font-weight-bold">Url: </label>
      <a :href="catalog.adsUrl" target="_blank" class="link-ads primary--text">{{ catalog.adsUrl }}</a>
    </div>
    <div class="mb-2">
      <label class="font-weight-bold d-block mb-1">Content:</label>
      <v-card class="pa-3" outlined>{{ catalog.adsContent }}</v-card>
    </div>
    <div class="mb-2">
      <label class="font-weight-bold d-block mb-1">Images target ({{ catalog.adsImages.length }}/10)</label>
      <Images :images="catalog.adsImages" class="mb-4" />
    </div>
    <div class="mb-2">
      <label class="font-weight-bold d-block mb-1">Audience ad targeting</label>
      <div v-html="catalog.adsHtml" class="pa-1"></div>
    </div>
    <div class="">
      <label class="font-weight-bold d-block mb-1">Videos campain {{ `(${catalog.videoUrls.length}/10)` }}</label>
      <div class="video-list">
        <video v-for="(video, key) in adsVideos" :key="key" :src="video" @click="onSelectVideo(video)"></video>
      </div>
      <v-dialog width="fit-content" height="fit-content" v-model="showPlayer">
        <v-card v-if="showPlayer" class="pa-0 player" color="transparent">
          <v-btn icon @click="showPlayer = false"><v-icon>mdi-close</v-icon></v-btn>
          <video :src="currentVideo" controls autoplay loop></video>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import Images from './catalog-image';
import imageHelpers from '@/helpers/image';
export default {
  props: ['catalog'],
  components: { Images },
  data() {
    return {
      showPlayer: false,
      currentVideo: null,
    };
  },
  computed: {
    adsVideos() {
      return this.catalog.videoUrls.map(video => imageHelpers.url(video, { width: 320, height: 320 }));
    },
  },
  methods: {
    onSelectVideo(video) {
      this.currentVideo = video;
      this.showPlayer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: var(--v-background-base);
}
.video-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  video {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
    cursor: pointer;
  }
}
.link-ads {
  text-decoration: none;
}
.player {
  position: relative;
  > button {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 10;
    background-color: white;
    i {
      color: rgb(32, 32, 32) !important;
    }
  }
}
.ads-tested {
  background-color: white;
  color: #1b1b1b;
  border-radius: 4px;
}
</style>
