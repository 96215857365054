<template>
  <div class="catalog-images">
    <img
      v-for="(image, key) in catalogImages"
      :key="key"
      :src="image"
      alt="Image"
      loading="lazy"
      @click="onPreview(image)"
    />
    <v-dialog v-model="showPreview" width="fit-content" height="fit-content">
      <v-card v-if="currentImage" class="pa-0 preview-image">
        <v-btn class="btn-close" color="secondary" icon @click="showPreview = false">
          <v-icon small>mdi-close</v-icon></v-btn
        >
        <img :src="currentImage" alt="Image" loading="lazy" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imageHelpers from '@/helpers/image';

export default {
  props: ['images'],
  data() {
    return {
      showPreview: false,
      currentImage: null,
    };
  },
  computed: {
    catalogImages() {
      return this.images.map(image => imageHelpers.url(image, { width: 320, height: 320 }));
    },
  },
  methods: {
    onPreview(image) {
      this.currentImage = image;
      this.showPreview = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-images {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 14px;
  img {
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 1;
    border: solid 1px #dadce0;
    &:hover {
      opacity: 0.8;
    }
  }
  img:first-child {
    grid-column: span 5 / span 5;
  }
}
.preview-image {
  position: relative;
  button.btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  img {
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    object-fit: contain;
  }
}
</style>
